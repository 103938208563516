<template>
  <div class="pt-1 m-1">
    <CRow>
      <LoadingOverlay v-if="isFetchingGenericFilter" />
      <CCol md="12">
        <GenericFilter
          :basicFilterlength="basicFilterLength"
          :filterSchema="currentPageSchema"
          :selectedFilters="selectedFilters"
          :filterDependencyObj="filterDependencyObj"
          :isFetchingFilter="isFetchingGenericFilter"
          :callback="updateRouterURL"
          :searchCallback="searchCallback"
          :filterAction="filterAction"
        />
        <CRow class="mb-3">
          <CCol md="6">
            <JobCount
              :totalCount="getJobCount"
              :filterCount="getFilterCount"
              :isJob="true"
            />
          </CCol>
          <CCol md="6">
            <Pagination
              :total="getFilterCount"
              :perPage="getJobBoardPaginationLimit"
              @pagechanged="pagechanged"
              :maxVisibleButtons="isMobile ? 3 : 5"
              :position="isMobile ? 'left' : 'end'"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12" class="mb-5">
        <CRow>
          <CCol v-for="(job, index) in getJobBoard" :key="index" sm="6" md="3">
            <div v-if="isJobBoardFetching" class="skeleton-card"></div>
            <div v-else>
              <JobCardNew :item="job" v-if="specificCustomer(job)" />
            </div>
          </CCol>
          <CCol
            class="d-flex justify-content-center"
            v-if="
              !isFetchingGenericFilter &&
              !isJobBoardFetching &&
              !getJobBoard.length
            "
          >
            <h1>No Data found!</h1>
          </CCol>
        </CRow>
        <div
          class="d-flex justify-content-center"
          v-if="
            getJobBoard.length &&
            getJobBoard.length < getJobBoardPaginationLimit &&
            !isFetchingGenericFilter &&
            !isJobBoardFetching
          "
        >
          <span class="btn-pill btn-primary p-2"
            >No more records to display!</span
          >
        </div>
      </CCol>
      <!-- <PopupModal
        :modalTitle="popupModal.modalTitle"
        :modalColor="popupModal.modalColor"
        :modalContent="popupModal.modalContent"
        :modalLabel="popupModal.modalLabel"
        :isShowPopup="popupModal.isShowPopup"
        :popupModalCallBack="popupModalCallBack"
        :candidateItem="popupModal.candidateItem"
        :modalJob="popupModal.modalJob"
        :modalReasonType="popupModal.modalReasonType"
      /> -->
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import CandidateCount from "@/components/Count";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role, getCustomerId } from "@/helpers/helper";
// import PopupModal from "@/components/reusable/PopupModal";
import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";
import JobBoard from "../JobBoard.vue";
import JobCardNew from "@/components/jobCardNew.vue";
import Pagination from "@/components/reusable/Pagination";

export default {
  extends: JobBoard,
  name: "CandidateMain",
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      isMobile: false,
      // popupModal: {
      //   modalColor: "",
      //   modalTitle: "",
      //   modalContent: "",
      //   modalLabel: "Select Reason Code",
      //   isShowPopup: false,
      //   candidateItem: {},
      //   modalJob: "",
      //   modalReasonType: "",
      // },
    };
  },
  components: {
    JobCount: CandidateCount,
    // PopupModal,
    GenericFilter,
    JobCardNew,
    Select,
    Pagination,
  },
  computed: {
    ...mapGetters([
      "currentPageSchema",
      "isFetchingGenericFilter",
      "filterDependencyObj",
      "selectedFilters",
      "getQueryResult",
      "isSelectedFilterUpdated",
      "getRoleScopes",
      "getOrgID",
      "getCustomerId",
      "getRelationshipOrgDetail",
      "getIsParentUser",
      "isTFWCustomerFromAccessToken"
    ]),
    basicFilterLength() {
      return this.currentUserRole === Role.systemAdmin
        ? 7
        : this.getIsParentUser
        ? 6
        : 7;
    },
  },
  methods: {
    ...mapActions(["fetchAllOrganisationList"]),
    specificCustomer(job) {
      if (
        this.getRoleScopes.length &&
        this.getRoleScopes[0] === "customer_admin" &&
        this.getRelationshipOrgDetail
      ) {
        return this.getRelationshipOrgDetail?.length ? true : false;
      } else if (
        this.getRoleScopes.length &&
        this.getRoleScopes[0] === "system_admin"
      ) {
        return true;
      }
      if (getCustomerId() === 1 || this.isTFWCustomerFromAccessToken) return true;
      else return job?.customer_uid == getCustomerId() ? true : false;
    },
    pagechanged(pageNumber) {
      let path = `${this.$route.path}?page=${pageNumber}`;
      if (this.getQueryResult.routeUrl)
        path = `${this.$route.path}?page=${pageNumber}&${this.getQueryResult.routeUrl}`;
      this.$router.push({ path });
      this.jobBoardAction({
        page: this.page,
        query: this.getQueryResult.queryUrl,
        pagination: false,
        pageNumber,
        isPageChanged:true,
      });
    },
  },
  mounted() {
    this.fetchAllOrganisationList();
    this.$store.commit("RESET_JOB_BOARD_DATA");
  },
};
</script>
