var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-1 m-1"},[_c('CRow',[(_vm.isFetchingGenericFilter)?_c('LoadingOverlay'):_vm._e(),_c('CCol',{attrs:{"md":"12"}},[_c('GenericFilter',{attrs:{"basicFilterlength":_vm.basicFilterLength,"filterSchema":_vm.currentPageSchema,"selectedFilters":_vm.selectedFilters,"filterDependencyObj":_vm.filterDependencyObj,"isFetchingFilter":_vm.isFetchingGenericFilter,"callback":_vm.updateRouterURL,"searchCallback":_vm.searchCallback,"filterAction":_vm.filterAction}}),_c('CRow',{staticClass:"mb-3"},[_c('CCol',{attrs:{"md":"6"}},[_c('JobCount',{attrs:{"totalCount":_vm.getJobCount,"filterCount":_vm.getFilterCount,"isJob":true}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('Pagination',{attrs:{"total":_vm.getFilterCount,"perPage":_vm.getJobBoardPaginationLimit,"maxVisibleButtons":_vm.isMobile ? 3 : 5,"position":_vm.isMobile ? 'left' : 'end'},on:{"pagechanged":_vm.pagechanged}})],1)],1)],1)],1),_c('CRow',[_c('CCol',{staticClass:"mb-5",attrs:{"md":"12"}},[_c('CRow',[_vm._l((_vm.getJobBoard),function(job,index){return _c('CCol',{key:index,attrs:{"sm":"6","md":"3"}},[(_vm.isJobBoardFetching)?_c('div',{staticClass:"skeleton-card"}):_c('div',[(_vm.specificCustomer(job))?_c('JobCardNew',{attrs:{"item":job}}):_vm._e()],1)])}),(
            !_vm.isFetchingGenericFilter &&
            !_vm.isJobBoardFetching &&
            !_vm.getJobBoard.length
          )?_c('CCol',{staticClass:"d-flex justify-content-center"},[_c('h1',[_vm._v("No Data found!")])]):_vm._e()],2),(
          _vm.getJobBoard.length &&
          _vm.getJobBoard.length < _vm.getJobBoardPaginationLimit &&
          !_vm.isFetchingGenericFilter &&
          !_vm.isJobBoardFetching
        )?_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"btn-pill btn-primary p-2"},[_vm._v("No more records to display!")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }